import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { userAtom, progressBarAtom, affiliateCodeAtom } from '../../atoms';
import SocialProfile from '../common/SocialProfile';
import authManager from '../common/authManager';
import Loading from '../common/Loading';
import { Categories, DiscordUrl, NftTypes } from '../../constants';
import { upsertUser } from '../../integration';
import CustomButton from '../common/CustomButton';

const Confirm = () => {
  const [affiliateCode] = useRecoilState(affiliateCodeAtom);
  const navigate = useNavigate();
  const [user, setUser] = useRecoilState(userAtom);
  const [, setProgressBar] = useRecoilState(progressBarAtom);
  const [state, setState] = useState({
    isSubmitting: false,
    isSubmitError: false,
  });

  const tryAgain = () => {
    setState({
      isSubmitting: false,
      isSubmitError: false,
    });
  };

  const submit = async () => {
    setState({ isSubmitting: true });

    let dbUser;
    try {
      dbUser = await upsertUser(user, true);
    } catch (err) {
      setState({ isSubmitError: true });
      console.error(err);
    }

    if (dbUser) {
      setUser({ ...dbUser });
      navigate('/application/finished');
    }
  };

  const onStepEnter = () => {
    document.title = 'MetaTope - Ready to submit';
    setProgressBar('done');
  };

  useEffect(() => {
    onStepEnter();
  }, []);

  if (state.isSubmitting) {
    return (
      <div className="section center-align">
        <h3 className="header">Please wait...</h3>
        <p>We are processing your application now...</p>
        <Loading />
      </div>
    );
  }

  if (state.isSubmitError) {
    return (
      <div className="section center-align">
        <h3 className="header">Whoops!</h3>
        <p>
          Looks like something went wrong... Please try again or contact us on Discord.
        </p>
        <p className="mt-3">
          <CustomButton onClick={tryAgain}>
            <i className="fa fa-chevron-left mr-0-5" /> Try again
          </CustomButton>
        </p>
        <p className="mt-3 mt-1 muted">or</p>
        <p className="mt-3">
          <CustomButton href={DiscordUrl}>
            <i className="iconcustom mr-0-5" /> Contact us
          </CustomButton>
        </p>
      </div>
    );
  }

  return (
    <div className="section center-align">
      <h3 className="header">Ready to submit?</h3>
      <p className="mb-3">Review your application below, then go for it.</p>
      <CustomButton onClick={submit}>
        Submit <i className="fa fa-chevron-right ml-0-5" />
      </CustomButton>
      <div className="confirm-box">
        <div className="divider-large mt-5" />
        <div className="row mt-2">
          <div className="col s12">
            <SocialProfile className="mt-1 mb-1" user={user} />
          </div>
        </div>
        <hr className="mt-3" />
        <div className="row mb-2">
          <div className="col s12 confirm-title">Email:</div>
          <div className="col s12 confirm-value">{user.email}</div>
        </div>
        <div className="row mb-2">
          <div className="col s12 confirm-title">Interested in:</div>
          <div className="col s12">
            <span className="muted">Mobile app:&nbsp;</span>
            <span className="confirm-value">
              {user.interestInMobile ? (
                <>
                  <i className="iconcustom-check" />
                  Yes
                </>
              ) : (
                <>
                  <i className="iconcustom-close" />
                  No
                </>
              )}
            </span>
          </div>
          <div className="col s12">
            <span className="muted">Presale:&nbsp;</span>
            <span className="confirm-value">
              {user.interestInPresale ? (
                <>
                  <i className="iconcustom-check" />
                  Yes
                </>
              ) : (
                <>
                  <i className="iconcustom-close" />
                  No
                </>
              )}
            </span>
          </div>
          <div className="col s12">
            <span className="muted">Emails:&nbsp;</span>
            <span className="confirm-value">
              {user.interestInEmail ? (
                <>
                  <i className="iconcustom-check" />
                  Yes
                </>
              ) : (
                <>
                  <i className="iconcustom-close" />
                  No
                </>
              )}
            </span>
          </div>
        </div>
        {user.wallet && user.wallet.address && (
          <div className="row mb-2">
            <div className="col s12 confirm-title">Ethereum Wallet:</div>
            <div className="col s12 confirm-value">
              {user.wallet.ensName || user.wallet.address}
            </div>
          </div>
        )}

        {!(affiliateCode && affiliateCode.shortForm) && (
          <>
            <hr />
            <div className="row mb-2">
              <div className="col s12 confirm-title">Category:</div>
              <div className="col s12 confirm-value">
                {Categories.find((cat) => cat.id === user.categories[0]).title}
              </div>
            </div>
            {user.categories[0] === 'enthusiast' && (
              <>
                <div className="row mb-2">
                  <div className="col s12 confirm-title">Q1: I hold these NFTs:</div>

                  {user.nftHoldings &&
                    user.nftHoldings.length > 0 &&
                    user.nftHoldings.map((nft) => {
                      const nftDetails = NftTypes.find((n) => n.id === nft);
                      return (
                        <div key={nftDetails.id} className="col s12 confirm-value">
                          {nftDetails.title}
                        </div>
                      );
                    })}
                  {user.nftHoldingsOther &&
                    user.nftHoldingsOther.length > 0 &&
                    user.nftHoldingsOther.map((nft) => {
                      return (
                        <div key={nft} className="col s12 confirm-value">
                          {nft}
                        </div>
                      );
                    })}
                  {(!user.nftHoldings || user.nftHoldings.length === 0) &&
                    (!user.nftHoldingsOther || user.nftHoldingsOther.length === 0) && (
                      <div className="confirm-value">None selected</div>
                    )}
                </div>
                <div className="row mb-2">
                  <div className="col s12 confirm-title">Q2: Additional wallets</div>
                  <div className="col s12 confirm-value">
                    {user.wallets &&
                      user.wallets.length > 0 &&
                      user.wallets.map((wallet) => {
                        return <div key={wallet}>{wallet}</div>;
                      })}
                    {!user.wallets ||
                      (user.wallets.length === 0 && <>No additional wallets</>)}
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col s12 confirm-title">
                    Q3: What do you look for in a project?
                  </div>
                  <div className="col s12 confirm-value">{user.answerProject}</div>
                </div>
                <div className="row mb-2">
                  <div className="col s12 confirm-title">
                    Q4: Why should we choose you?
                  </div>
                  <div className="col s12 confirm-value">{user.answerWhyYou}</div>
                </div>
              </>
            )}
            {user.categories[0] === 'builder' && (
              <>
                <div className="row mb-2">
                  <div className="col s12 confirm-title">
                    Q1: What industry are you in?
                  </div>
                  <div className="col s12 confirm-value">{user.answerIndustry}</div>
                </div>
                <div className="row mb-2">
                  <div className="col s12 confirm-title">
                    Q2: What are you working on now?
                  </div>
                  <div className="col s12 confirm-value">{user.answerWorkingNow}</div>
                </div>
                <div className="row mb-2">
                  <div className="col s12 confirm-title">
                    Q3: What will you be working on in 2-5 years?
                  </div>
                  <div className="col s12 confirm-value">{user.answerWorkingFuture}</div>
                </div>
              </>
            )}
            {user.categories[0] === 'project' && (
              <>
                <div className="row mb-2">
                  <div className="col s12 confirm-title">
                    Q1: Who are you and what is your project?
                  </div>
                  <div className="col s12 confirm-value">{user.answerWho}</div>
                </div>
                <div className="row mb-2">
                  <div className="col s12 confirm-title">
                    Q2: What tech are you built on?
                  </div>
                  <div className="col s12 confirm-value">{user.answerTech}</div>
                </div>
                <div className="row mb-2">
                  <div className="col s12 confirm-title">
                    Q3: What is unique about your project & why should we choose you?
                  </div>
                  <div className="col s12 confirm-value">{user.answerWhy}</div>
                </div>
              </>
            )}
            {user.categories[0] === 'content-creator' && (
              <>
                <div className="row mb-2">
                  <div className="col s12 confirm-title">
                    Q1: What is/are your main platform(s)?
                  </div>
                  <div className="col s12 confirm-value">{user.answerPlatform}</div>
                </div>
                <div className="row mb-2">
                  <div className="col s12 confirm-title">
                    Q2: What type of content do you create?
                  </div>
                  <div className="col s12 confirm-value">{user.answerContentType}</div>
                </div>
                <div className="row mb-2">
                  <div className="col s12 confirm-title">
                    Q3: How often do you create content?
                  </div>
                  <div className="col s12 confirm-value">{user.answerOften}</div>
                </div>
                <div className="row mb-2">
                  <div className="col s12 confirm-title">Q4: What is your following?</div>
                  <div className="col s12 confirm-value">{user.answerFollowers}</div>
                </div>
              </>
            )}
            {user.categories[0] === 'creative' && (
              <>
                <div className="row mb-2">
                  <div className="col s12 confirm-title">
                    Q1: What industry are you in?
                  </div>
                  <div className="col s12 confirm-value">{user.answerIndustry}</div>
                </div>
                <div className="row mb-2">
                  <div className="col s12 confirm-title">
                    Q2: What do you create and how?
                  </div>
                  <div className="col s12 confirm-value">{user.answerCreateWhat}</div>
                </div>
              </>
            )}
            {user.categories[0] === 'other' && (
              <>
                <div className="row mb-2">
                  <div className="col s12 confirm-title">Q1: Who are you?</div>
                  <div className="col s12 confirm-value">{user.answerWho}</div>
                </div>
                <div className="row mb-2">
                  <div className="col s12 confirm-title">
                    Q2: What do you like about MetaTope?
                  </div>
                  <div className="col s12 confirm-value">{user.answerWhat}</div>
                </div>
                <div className="row mb-2">
                  <div className="col s12 confirm-title">
                    Q3: Why should we choose you?
                  </div>
                  <div className="col s12 confirm-value">{user.answerWhy}</div>
                </div>
              </>
            )}
            {user.links && user.links.length > 0 && (
              <>
                <div className="row mb-2">
                  <div className="col s12 confirm-title">Your work:</div>
                  {user.links.map((link) => {
                    return (
                      <div key={link} className="col s12 confirm-value">
                        {link}
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </>
        )}
        {user.affiliateCode && (
          <>
            <div className="row mb-4">
              <div className="col s12 confirm-title">Project Specialist Code:</div>
              <div className="col s12 confirm-value">{user.affiliateCode}</div>
            </div>
          </>
        )}
        <div className="divider-large mt-5 mb-5" />
      </div>
      <CustomButton onClick={submit}>
        Submit <i className="fa fa-chevron-right ml-0-5" />
      </CustomButton>
    </div>
  );
};

export default Confirm;
