import React from 'react';
import { PremintUrl } from '../../constants';
import CustomButton from './CustomButton';

const SubmitChecklist = ({ isPresaleDone }) => {
  return (
    <>
      <div>
        <table className="finished-check-list">
          <tbody>
            <tr>
              <td className="td-check">
                <span className="check-wrapper check-success">
                  <i className="fa fa-check" />
                </span>
              </td>
              <td className="td-text">
                <div>
                  <strong>Step 1:</strong>
                </div>
                <div className="muted">
                  Submit your application
                  <span className="ml-0-5 badge-small badge-success">Done</span>
                </div>
              </td>
            </tr>
            <tr>
              <td className="td-check">
                {!isPresaleDone ? (
                  <span className="check-wrapper check-warning" />
                ) : (
                  <span className="check-wrapper check-success">
                    <i className="fa fa-check" />
                  </span>
                )}
              </td>
              <td>
                <div>
                  <strong>Step 2:</strong>
                </div>
                <div className="muted">
                  Register for Presale
                  {!isPresaleDone ? (
                    <span className="ml-0-5 badge-small badge-warning">Not Done</span>
                  ) : (
                    <span className="ml-0-5 badge-small badge-success">Done</span>
                  )}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {!isPresaleDone && (
        <>
          <p className="check-warning mt-2">
            <i className="fa fa-exclamation-triangle" />
            <br />
            <strong>You may still need to register for the presale.</strong>
          </p>
          <p className="muted mb-2">
            If you have not registered for the presale yet, please
            <br /> register using the same details you used for this application.
          </p>
          <p className="muted mb-2">
            Premint Registration may take up to 24 hours to show as complete.
          </p>
        </>
      )}
      <CustomButton href={PremintUrl} target="_blank" className="mt-3">
        <i className="iconcustom-external-link mr-0-5" />
        Go to PreSale
      </CustomButton>
    </>
  );
};

export default SubmitChecklist;
