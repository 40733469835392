import { UserManager } from 'oidc-client';

const AuthBaseUrl = process.env.REACT_APP_AUTH0_URL;
const ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const BaseUrl = window.location.origin;

const oidcSettings = ({ connection, callbackPath } = {}) => {
  return {
    authority: AuthBaseUrl,
    client_id: ClientId,
    response_type: 'code',
    scope: 'openid profile email offline_access',
    redirect_uri: callbackPath
      ? new URL(callbackPath, BaseUrl).href
      : new URL('/application/callback', BaseUrl).href,
    post_logout_redirect_uri: BaseUrl,
    metadata: {
      issuer: `${AuthBaseUrl}/`,
      authorization_endpoint: connection
        ? `${AuthBaseUrl}/authorize?connection=${connection}`
        : `${AuthBaseUrl}/authorize`,
      token_endpoint: `${AuthBaseUrl}/oauth/token`,
      jwks_uri: `${AuthBaseUrl}/.well-known/jwks.json`,
      userinfo_endpoint: `${AuthBaseUrl}/userinfo`,
      end_session_endpoint: `${AuthBaseUrl}/v2/logout`,
    },
  };
};

const defaultOidcManager = new UserManager(oidcSettings({}));

const authManager = {
  signinRedirect: ({ connection, callbackPath }) => {
    const oidcManager = new UserManager(oidcSettings({ connection, callbackPath }));
    oidcManager.signinRedirect();
  },

  signinRedirectCallback: async ({ connection, callbackPath }) => {
    // exchange authorization code for access token
    const oidcManager = new UserManager(oidcSettings({ connection, callbackPath }));
    await oidcManager.signinRedirectCallback();
  },

  getUser: async () => {
    // exchange authorization code for access token
    return defaultOidcManager.getUser();
  },

  logout: async () => {
    return defaultOidcManager.removeUser();
  },
};

export default authManager;
