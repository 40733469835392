import React from 'react';
import { useNavigate } from 'react-router-dom';

const CustomButton = ({ children, className, onClick, href, target }) => {
  const navigate = useNavigate();

  const openLink = (url) => {
    if (target === '_blank') {
      window.open(url);
    } else {
      navigate(url);
    }
  };

  return (
    <>
      <button
        type="button"
        className={`custom-button ${className}`}
        onClick={href ? () => openLink(href) : onClick}
      >
        <span className="custom-button-left" />
        <span className="custom-button-middle">{children}</span>
        <span className="custom-button-right" />
      </button>
    </>
  );
};

export default CustomButton;
