import _ from 'lodash';

export const isBrowser = typeof window !== 'undefined';
export const isNode = !isBrowser;
export const userAgent = isBrowser ? _.get(window, 'navigator.userAgent') : '';
export const hasEthereum = isBrowser && _.has(window, 'ethereum');
export const isAndroid = /(Android)/i.test(userAgent);
export const isIphone = /(iPhone|iPod)/i.test(userAgent);
export const isIpad = /(iPad)/i.test(userAgent);
export const isMobile = isIphone || isAndroid;

// A mobile browser with ethereum we assume it's Metamask Browser
export const isMetamaskApp = isMobile && hasEthereum;
