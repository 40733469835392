import React from 'react';
import { useRecoilState } from 'recoil';
import { affiliateCodeAtom } from '../../atoms';
import CustomButton from './CustomButton';

const CheckBar = ({ forceGoBack }) => {
  const [affiliateCode] = useRecoilState(affiliateCodeAtom);
  const isGoBack = forceGoBack || window.location.pathname.startsWith('/check');

  return (
    <div className="checkbar-container">
      <div className="checkbar-affiliate-code">
        {affiliateCode && affiliateCode.displayName && (
          <>
            <i className="fa fa-check-circle" /> {affiliateCode.displayName}
          </>
        )}
      </div>
      <div>
        {!isGoBack ? (
          <CustomButton className="custom-button-small" href="/check">
            Check Status
          </CustomButton>
        ) : (
          <a
            role="button"
            href="/"
            className="waves-effect waves-light btn-large check-status-button back-button"
          >
            <i className="fa fa-chevron-left" />
            Go back
          </a>
        )}
      </div>
    </div>
  );
};

export default CheckBar;
