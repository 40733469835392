import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { progressBarAtom, userAtom } from '../../atoms';
import { Categories } from '../../constants';
import CustomButton from '../common/CustomButton';

const CategoryCapture = () => {
  const navigate = useNavigate();
  const [, setProgressBar] = useRecoilState(progressBarAtom);
  const [user, setUser] = useRecoilState(userAtom);
  const [state, setState] = useState({ isError: false });

  const onStepEnter = () => {
    document.title = 'MetaTope - Select a category';
    setProgressBar('categories');
  };

  const nextStep = () => {
    const categoryId = user.categories[0];
    navigate(`/application/${categoryId}`);
  };

  useEffect(() => {
    onStepEnter();
  }, []);

  const isCategorySelected = (category) => {
    return user.categories && user.categories.find((cat) => cat === category.id);
  };

  const selectCategory = async (category) => {
    let categories = user.categories ?? [];

    // allow only selection
    if (!isCategorySelected(category)) {
      categories = [category.id]; // [...categories, category.id];
    } else {
      categories = []; // categories.filter((cat) => cat !== category.id);
    }

    setUser({ ...user, categories });
    setState({ isError: false });
  };

  const goToNextStep = () => {
    if (!user.categories || user.categories.length === 0) {
      setState({ isError: true });
    } else {
      nextStep();
    }
  };

  return (
    <>
      <div className="section center-align">
        <h3 className="header">Choose the bucket that fits you</h3>
        <p className="mb-3">Choose your path.</p>
        <div className="row">
          {Categories.map((category) => {
            return (
              <div key={category.id} className="col s12 l4 m6 mb-2">
                <div
                  role="none"
                  className={`radio-button category-radio-button ${
                    isCategorySelected(category) ? 'selected' : ''
                  }`}
                  onClick={() => selectCategory(category)}
                >
                  <i className="fa fa-check" />
                  <div className="category-title">{category.title}</div>
                  <div className="category-description">{category.description}</div>
                </div>
              </div>
            );
          })}
        </div>
        {state.isError ? (
          <span className="helper-text error-text">
            Whoops.. looks like you didn&apos;t select an option.
          </span>
        ) : (
          <span className="helper-text error-text">&nbsp;</span>
        )}

        <p className="mt-2">
          <CustomButton onClick={goToNextStep}>
            Next <i className="fa fa-chevron-right ml-0-5" />
          </CustomButton>
        </p>
      </div>
    </>
  );
};

export default CategoryCapture;
