import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { userAtom, progressBarAtom, affiliateCodeAtom } from '../../atoms';
import CustomButton from '../common/CustomButton';

import ErrorPanel from '../common/ErrorPanel';
import Loading from '../common/Loading';
import LoadingButton from '../common/LoadingButton';

const { REACT_APP_BACKEND_API_URL } = process.env;

const AffiliateCodeCapture = (props) => {
  const navigate = useNavigate();
  const [user, setUser] = useRecoilState(userAtom);
  const [, setProgressBar] = useRecoilState(progressBarAtom);
  const [linkAffiliateCode] = useRecoilState(affiliateCodeAtom);
  const [isValidatingCode, setIsValidatingCode] = useState(false);
  const [showInput, setShowInput] = useState(
    !!(user && user.affiliateCode && user.affiliateCode !== '')
  );
  const [state, setState] = useState({
    isError: false,
  });

  const nextStep = async () => {
    navigate('/application/confirm');
  };

  const skipStep = () => {
    setUser({ ...user, affiliateCode: undefined });
    nextStep();
  };

  const isValidCode = async (code) => {
    setIsValidatingCode(true);
    if (!code || code.trim().length === 0) {
      return false;
    }

    // get code from api
    let response;

    try {
      response = await axios.post(
        new URL('/signup/code', REACT_APP_BACKEND_API_URL).href,
        { codeName: code.toLowerCase() }
      );
    } catch (err) {
      console.error(err);
    }

    setIsValidatingCode(false);
    return !!response;
  };

  const updateAffiliateCode = (e) => {
    setState({ isError: false });
    const affiliateCode = e.target.value;
    setUser({ ...user, affiliateCode });
  };

  const goToNextStep = async () => {
    setState({ isError: false });

    if (!(await isValidCode(user.affiliateCode))) {
      setState({ isError: true });
    } else {
      nextStep();
    }
  };

  const onStepEnter = () => {
    document.title = 'MetaTope - Project Specialist Code';
    setProgressBar('questions');

    // skip affiliate code and auto set if using affiliate link
    if (!user.affiliateCode && linkAffiliateCode.codeName) {
      setUser({ ...user, affiliateCode: linkAffiliateCode.displayName });
      navigate('/application/confirm', { replace: true });
    }

    if (
      user.affiliateCode &&
      user.affiliateCode.toLowerCase() === linkAffiliateCode.codeName
    ) {
      navigate('/application/confirm', { replace: true });
    }
  };

  useEffect(() => {
    onStepEnter();
  }, []);

  return (
    <div className="section center-align">
      <h4 className="sub-header">One last question...</h4>
      <h3 className="header">
        Do you have a<br />
        project specialist code?
      </h3>
      {!showInput && (
        <div className="form-group">
          <p>
            <CustomButton onClick={skipStep}>
              No, I don&apos;t have one <i className="fa fa-chevron-right ml-0-5" />
            </CustomButton>
          </p>
          <p className="mt-2">
            <CustomButton onClick={() => setShowInput(true)}>
              Yes, I have a code <i className="fa fa-chevron-down ml-0-5" />
            </CustomButton>
          </p>
        </div>
      )}
      {showInput && (
        <div className="form-group">
          <p>
            If you have a code, please enter it below, otherwise you can skip this step.
          </p>
          <input
            type="text"
            className="form-control"
            name="code"
            onChange={updateAffiliateCode}
            defaultValue={user.affiliateCode ?? ''}
          />

          {state.isError ? (
            <span className="helper-text error-text">
              Hmm... are you sure you entered a valid code?
            </span>
          ) : (
            <span className="helper-text error-text">&nbsp;</span>
          )}

          <p className="mt-3">
            {!isValidatingCode && (
              <CustomButton onClick={goToNextStep}>
                Next <i className="fa fa-chevron-right ml-0-5" />
              </CustomButton>
            )}
            {isValidatingCode && <LoadingButton />}
          </p>
          <p className="muted">or</p>
          <p>
            <button type="button" className="text-button" onClick={() => skipStep()}>
              Click here to skip this step
            </button>
          </p>
        </div>
      )}
    </div>
  );
};
export default AffiliateCodeCapture;
