import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { affiliateCodeAtom, progressBarAtom, userAtom } from '../../atoms';
import CustomButton from '../common/CustomButton';

const LinksCapture = () => {
  const navigate = useNavigate();
  const [user, setUser] = useRecoilState(userAtom);
  const [, setProgressBar] = useRecoilState(progressBarAtom);

  let questionNumber = 4;
  if (user.categories[0] === 'enthusiast' || user.categories[0] === 'content-creator') {
    questionNumber = 5;
  } else if (user.categories[0] === 'creative') {
    questionNumber = 3;
  }

  const [state, setState] = useState({
    links: user && user.links && user.links.length !== 0 ? user.links : [''],
    isError: false,
  });

  const nextStep = () => {
    navigate('/application/update');
  };

  const updateLink = (index, value) => {
    const newLinks = [...state.links];
    newLinks[index] = value;
    setState({ ...state, links: newLinks });
  };

  const goToNextStep = () => {
    setUser({
      ...user,
      links: state.links.filter((link) => link !== ''),
    });
    nextStep();
  };

  const addLink = () => {
    state.links = [...state.links, ''];
    setState({ ...state, links: state.links });
  };

  const removeLink = (index) => {
    const links = [...state.links];
    links.splice(index, 1);
    setState({ ...state, links });
  };

  const onStepEnter = () => {
    document.title = 'MetaTope - Show off your work';
    setProgressBar('questions');
  };

  useEffect(() => {
    onStepEnter();
  }, []);

  return (
    <>
      <div className="section center-align">
        <h4 className="sub-header">
          Question {questionNumber} of {questionNumber}
        </h4>
        <h3 className="header">Proof of work</h3>
        <p className="mb-3">
          Time to brag about your socials, website, articles, and other relevant
          information.
        </p>
        {state.links.map((link, i) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <div className="p" key={i}>
              <h4 className="sub-header">
                Link {`${state.links.length > 1 ? i + 1 : 'URL'}`}
              </h4>
              {i !== 0 && (
                <button
                  type="button"
                  className="text-button top-right"
                  onClick={() => removeLink(i)}
                >
                  <i className="fa fa-window-close" />
                  &nbsp; Remove
                </button>
              )}
              <input
                value={link}
                type="email"
                className="form-control"
                name={`link_${i}`}
                onChange={(e) => updateLink(i, e.target.value)}
              />
            </div>
          );
        })}

        <p>
          <button type="button" className="text-button" onClick={addLink}>
            <i className="fa fa-plus" />
            &nbsp; Add another link
          </button>
        </p>
        <p className="mt-4">
          <CustomButton onClick={goToNextStep}>
            Next <i className="fa fa-chevron-right ml-0-5" />
          </CustomButton>
        </p>
      </div>
    </>
  );
};

export default LinksCapture;
