import { data } from 'autoprefixer';
import axios from 'axios';
import authManager from './components/common/authManager';

const { REACT_APP_BACKEND_API_URL } = process.env;

export const upsertUser = async (user, isFinalSubmission = false) => {
  const authUser = await authManager.getUser();

  if (!authUser) {
    throw new Error('Invalid auth user');
  }

  const accessToken = authUser.access_token;

  // append ?final=true if this is the last step in the app
  const query = isFinalSubmission ? '?final=true' : '';

  // post to api - if non 200 response, function will throw an error
  const response = await axios.post(
    new URL(`/signup/submit${query}`, REACT_APP_BACKEND_API_URL).href,
    user,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  if (response && response.data && response.data.isSuccessful) {
    // return user object
    return response.data.user;
  }

  // otherwise return error message from api
  throw new Error(response.data.error);
};

export default upsertUser;
