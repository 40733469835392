import React from 'react';
import { DiscordUrl } from '../../constants';
import CustomButton from './CustomButton';

const ErrorPanel = () => {
  return (
    <div className="section center-align">
      <h3 className="header">Whoops!</h3>
      <p>Looks like something went wrong... Please try again or contact us on Discord.</p>
      <p className="mt-3">
        <CustomButton href={DiscordUrl} target="_blank">
          <i className="fa fa-chevron-left mr-0-5" /> Contact us on Discord
        </CustomButton>
      </p>
    </div>
  );
};

export default ErrorPanel;
