import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { progressBarAtom, userAtom } from '../../../atoms';
import { NftTypes } from '../../../constants';
import CustomButton from '../../common/CustomButton';

const NftCapture = () => {
  const navigate = useNavigate();
  const [, setProgressBar] = useRecoilState(progressBarAtom);
  const [user, setUser] = useRecoilState(userAtom);
  const [state, setState] = useState({
    isError: false,
    nftHoldingsOther: user.nftHoldingsOther ?? [''],
  });
  const [isNftOtherSelected, setIsNftOtherSelected] = useState(
    user.nftHoldingsOther && user.nftHoldingsOther.length > 0
  );

  const onStepEnter = () => {
    document.title = 'MetaTope - What NFTs do you hold?';
    setProgressBar('questions');
  };

  const nextStep = () => {
    navigate(`/application/enthusiast/wallet`);
  };

  useEffect(() => {
    onStepEnter();
  }, []);

  const isNftSelected = (nft) => {
    return user.nftHoldings && user.nftHoldings.find((n) => n === nft.id);
  };

  const toggleNftOther = () => {
    const isSelected = !isNftOtherSelected;
    setIsNftOtherSelected(isSelected);
  };

  const selectNft = async (nft) => {
    let nftHoldings = user.nftHoldings ?? [];

    // allow only selection
    if (!isNftSelected(nft)) {
      nftHoldings = [...nftHoldings, nft.id];
    } else {
      nftHoldings = nftHoldings.filter((n) => n !== nft.id);
    }

    setUser({ ...user, nftHoldings });
    setState({ ...state, isError: false });
  };

  const goToNextStep = () => {
    /*
    // check if at least 1 nft is selected
    if (!user.nftHoldings || user.nftHoldings.length === 0) {
      setState({ isError: true });
    }
    */
    const nftHoldingsOther = isNftOtherSelected
      ? state.nftHoldingsOther.filter((x) => x !== '')
      : undefined;

    setUser({ ...user, nftHoldingsOther });
    nextStep();
  };

  const updateNftOther = (index, value) => {
    state.nftHoldingsOther[index] = value;
    setState({ ...state, nftHoldingsOther: state.nftHoldingsOther });
  };

  const addNftOther = () => {
    state.nftHoldingsOther = [...state.nftHoldingsOther, ''];
    setState({ ...state, nftHoldingsOther: state.nftHoldingsOther });
  };

  const removeNftOther = (index) => {
    const nftHoldingsOther = [...state.nftHoldingsOther];
    nftHoldingsOther.splice(index, 1);
    setState({ ...state, nftHoldingsOther });
  };

  return (
    <>
      <div className="section center-align">
        <h4 className="sub-header">Question 1 of 5</h4>
        <h3 className="header">Do you hold any of these NFTs?</h3>
        <p className="mb-3">Select from the list below.</p>
        <div className="row">
          {NftTypes.map((nft) => {
            return (
              <div key={nft.id} className="col s6 l4 m4 mb-2">
                <div
                  role="none"
                  className={`radio-button nft-radio-button ${
                    isNftSelected(nft) ? 'selected' : ''
                  }`}
                  onClick={() => selectNft(nft)}
                >
                  <i className="fa fa-check" />
                  <div className={`nft-image ${nft.id}`} />
                  <div className="nft-title">{nft.title}</div>
                  <div className="nft-chain muted">{nft.chain}</div>
                </div>
              </div>
            );
          })}
          <div className="col s6 l4 m4 mb-2">
            <div
              role="none"
              className={`radio-button nft-radio-button ${
                isNftOtherSelected ? 'selected' : ''
              }`}
              onClick={() => toggleNftOther()}
            >
              <i className="fa fa-check" />
              <div className="nft-image nft-image-other">
                <i className="fa fa-question" />
              </div>
              <div className="nft-title">Other</div>
              <div className="nft-chain muted">Please specify...</div>
            </div>
          </div>
        </div>

        {isNftOtherSelected && (
          <>
            {state.nftHoldingsOther &&
              state.nftHoldingsOther.map((link, i) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <div className="p" key={i}>
                    <h4 className="sub-header">
                      NFT {`${state.nftHoldingsOther.length > 1 ? i + 1 : ''}`}
                    </h4>
                    {i !== 0 && (
                      <button
                        type="button"
                        className="text-button top-right"
                        onClick={() => removeNftOther(i)}
                      >
                        <i className="fa fa-window-close" />
                        &nbsp; Remove
                      </button>
                    )}
                    <input
                      value={link}
                      type="email"
                      className="form-control"
                      name={`link_${i}`}
                      onChange={(e) => updateNftOther(i, e.target.value)}
                    />
                  </div>
                );
              })}

            <p>
              <button type="button" className="text-button" onClick={addNftOther}>
                <i className="fa fa-plus" />
                &nbsp; Add another NFT
              </button>
            </p>
          </>
        )}

        {state.isError ? (
          <span className="helper-text error-text">
            Whoops.. looks like you didn&apos;t select an option.
          </span>
        ) : (
          <span className="helper-text error-text">&nbsp;</span>
        )}

        <p className="mt-2">
          <CustomButton onClick={goToNextStep}>
            Next <i className="fa fa-chevron-right ml-0-5" />
          </CustomButton>
        </p>
      </div>
    </>
  );
};

export default NftCapture;
