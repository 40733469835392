import React from 'react';
import moment from 'moment';
import {
  DiscordUrl,
  InstagramUrl,
  TiktokUrl,
  TwitterUrl,
  WebsiteName,
  WebsiteUrl,
  YoutubeUrl,
} from '../../constants';

const Footer = () => {
  return (
    <footer>
      <div className="footer-content">
        <ul className="footer-icon-links pt-0 pr-0 float-right">
          <li className="footer-icon-item">
            <a target="_blank" rel="noreferrer" title="Home" href="/">
              <i className="footer-icon footer-icon-home" />
            </a>
          </li>
          <li className="footer-icon-item mb-0">
            <a target="_blank" rel="noreferrer" title="Discord" href={DiscordUrl}>
              <i className="footer-icon footer-icon-discord" />
            </a>
          </li>
          <li className="footer-icon-item mb-0">
            <a target="_blank" rel="noreferrer" title="Twitter" href={TwitterUrl}>
              <i className="footer-icon footer-icon-twitter" />
            </a>
          </li>
          <li className="footer-icon-item mb-0">
            <a target="_blank" rel="noreferrer" title="Instagram" href={InstagramUrl}>
              <i className="footer-icon footer-icon-instagram" />
            </a>
          </li>
          <li className="footer-icon-item mb-0">
            <a target="_blank" rel="noreferrer" title="TikTok" href={TiktokUrl}>
              <i className="footer-icon footer-icon-tiktok" />
            </a>
          </li>
          <li className="footer-icon-item mb-0">
            <a target="_blank" rel="noreferrer" title="Youtube" href={YoutubeUrl}>
              <i className="footer-icon footer-icon-youtube" />
            </a>
          </li>
        </ul>
        <p className="footer-copyright">
          <a className="muted" target="_blank" rel="noreferrer" href={WebsiteUrl}>
            {moment().format('yyyy')} {WebsiteName}. All Rights Reserved.
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
