import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const persistToSessionStorage = recoilPersist({
  storage: sessionStorage,
}).persistAtom;

const persistToLocalStorage = recoilPersist({
  storage: localStorage,
}).persistAtom;

export const affiliateCodeAtom = atom({
  key: 'affiliate-code',
  default: {
    codeName: null,
    displayName: null,
  },
  effects_UNSTABLE: [persistToLocalStorage],
});

export const walletAtom = atom({
  key: 'wallet',
  default: {
    isConnected: false,
    address: null,
    error: null,
    requestId: null,
    signature: null,
  },
});

export const userAtom = atom({
  key: 'user',
  default: null,
  effects_UNSTABLE: [persistToSessionStorage],
});

export const progressBarAtom = atom({
  key: 'progressBar',
  default: null,
});

export const stepAtom = atom({
  key: 'step',
  default: 0,
});

export const formAtom = atom({
  key: 'form',
  default: {
    alreadyRegistered: false,
    isSubmitting: false,
  },
});

export const tiersAtom = atom({
  key: 'tiers',
  default: [],
});

export const currentTierAtom = atom({
  key: 'currentTier',
  default: {},
});
