import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useLocation, useNavigate } from 'react-router-dom';
import { userAtom, progressBarAtom } from '../../atoms';
import Loading from '../common/Loading';
import { DiscordUrl } from '../../constants';
import { upsertUser } from '../../integration';
import CustomButton from '../common/CustomButton';

let isSaving = false;

const Update = () => {
  const navigate = useNavigate();
  const [user, setUser] = useRecoilState(userAtom);
  const [, setProgressBar] = useRecoilState(progressBarAtom);
  const [state, setState] = useState({ isError: false });

  const submit = async () => {
    isSaving = true;
    setState({ isError: false });

    let dbUser;
    try {
      dbUser = await upsertUser(user);
    } catch (err) {
      setState({ isError: true });
      console.error(err);
    }

    if (dbUser) {
      setUser({ ...dbUser });
      navigate('/application/affiliate', { replace: true });
    }

    isSaving = false;
  };

  const onStepEnter = () => {
    document.title = 'MetaTope - Updating application';
    setProgressBar('questions');
    submit();
  };

  const tryAgain = () => {
    submit();
  };

  useEffect(() => {
    if (!isSaving) {
      onStepEnter();
    }
  }, []);

  if (state.isError) {
    return (
      <div className="section center-align">
        <h3 className="header">Whoops!</h3>
        <p>
          Looks like something went wrong... Please try again or contact us on Discord.
        </p>
        <p className="mt-3">
          <CustomButton onClick={tryAgain}>
            <i className="fa fa-chevron-left mr-0-5" /> Try again
          </CustomButton>
        </p>
        <p className="mt-3 mt-1 muted">or</p>
        <p className="mt-3">
          <CustomButton href={DiscordUrl}>
            <i className="iconcustom mr-0-5" /> Contact us
          </CustomButton>
        </p>
      </div>
    );
  }

  return (
    <div className="section center-align">
      <h3 className="header">Please wait...</h3>
      <p>Updating application...</p>
      <Loading />
    </div>
  );
};

export default Update;
