import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { progressBarAtom, userAtom } from '../../../atoms';
import CustomButton from '../../common/CustomButton';

const AdditionalWalletCapture = () => {
  const navigate = useNavigate();
  const [user, setUser] = useRecoilState(userAtom);
  const [, setProgressBar] = useRecoilState(progressBarAtom);

  const [state, setState] = useState({
    wallets: user && user.wallets && user.wallets.length !== 0 ? user.wallets : [''],
    isError: false,
  });

  const nextStep = () => {
    navigate('/application/enthusiast/project');
  };

  const skipStep = () => {
    setUser({ ...user, wallets: [] });
    nextStep();
  };

  const updateWallet = (index, value) => {
    const newState = [...state.wallets];
    newState[index] = value;
    setState({ ...state, wallets: [...newState] });
  };

  const goToNextStep = () => {
    setUser({
      ...user,
      wallets: state.wallets.filter((wallet) => wallet !== ''),
    });
    nextStep();
  };

  const addWallet = () => {
    state.wallets = [...state.wallets, ''];
    setState({ ...state, wallets: state.wallets });
  };

  const removeWallet = (index) => {
    const wallets = [...state.wallets];
    wallets.splice(index, 1);
    setState({ ...state, wallets });
  };

  const onStepEnter = () => {
    document.title = 'MetaTope - Any other wallets?';
    setProgressBar('questions');
  };

  useEffect(() => {
    onStepEnter();
  }, []);

  return (
    <>
      <div className="section center-align">
        <h4 className="sub-header">Question 2 of 5</h4>
        <h3 className="header">Any other wallets?</h3>
        <p>
          Please enter any other wallet address below (e.g. Ethereum, Solana, Cardano,
          etc).
        </p>
        <p className="mb-4 muted">Note: this step is optional.</p>
        {state.wallets.map((wallet, i) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <div className="p" key={i}>
              <h4 className="sub-header">
                Wallet {`${state.wallets.length > 1 ? i + 1 : 'address'}`}
              </h4>
              {i !== 0 && (
                <button
                  type="button"
                  className="text-button top-right"
                  onClick={() => removeWallet(i)}
                >
                  <i className="fa fa-window-close" />
                  &nbsp; Remove
                </button>
              )}
              <input
                value={wallet}
                type="email"
                className="form-control"
                name={`link_${i}`}
                onChange={(e) => updateWallet(i, e.target.value)}
              />
            </div>
          );
        })}

        <p>
          <button type="button" className="text-button" onClick={addWallet}>
            <i className="fa fa-plus" />
            &nbsp; Add another wallet
          </button>
        </p>
        <p className="mt-5">
          <CustomButton onClick={goToNextStep}>
            Next <i className="fa fa-chevron-right ml-0-5" />
          </CustomButton>
        </p>
        <p className="muted">or</p>
        <p>
          <button type="button" className="text-button" onClick={() => skipStep()}>
            Click here to skip this step
          </button>
        </p>
      </div>
    </>
  );
};

export default AdditionalWalletCapture;
