import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useRecoilState } from 'recoil';
import { useNavigate, useParams } from 'react-router-dom';
import CheckBar from '../components/common/CheckBar';
import CustomButton from '../components/common/CustomButton';
import Loading from '../components/common/Loading';

import Logo from '../components/common/Logo';

import { affiliateCodeAtom, userAtom } from '../atoms';
import { DiscordUrl } from '../constants';

const { REACT_APP_BACKEND_API_URL } = process.env;

const AffiliateLanding = (props) => {
  const [user, setUser] = useRecoilState(userAtom);
  const [, setAffiliateCode] = useRecoilState(affiliateCodeAtom);

  const [state, setState] = useState({
    isLoading: true,
    isError: false,
  });

  // get code from url
  const { urlAffiliateCode } = useParams();
  const navigate = useNavigate();

  const checkCode = async () => {
    // get code from api
    let response;

    try {
      response = await axios.post(
        new URL('/signup/code', REACT_APP_BACKEND_API_URL).href,
        { codeName: urlAffiliateCode.toLowerCase() }
      );
    } catch (err) {
      setState({ isError: true });
      console.error(err);
    }

    if (response) {
      const code = response.data.data;
      if (code.status === 'disabled') {
        setState({ isError: true });
      } else {
        setUser({ ...user, affiliateCode: code.displayName });
        setAffiliateCode(code);
        navigate('/');
      }
    }
  };

  useEffect(() => {
    checkCode();
  }, []);

  if (state.isLoading) {
    return (
      <>
        <div className="container">
          <Logo />
          <div className="section center-align">
            <Loading />
          </div>
        </div>
      </>
    );
  }

  if (state.isError) {
    return (
      <>
        <div className="container">
          <Logo />
          <div className="section center-align">
            <h3 className="header">Whoops!</h3>
            <p>
              Looks like your Project Specialist code is invalid or something else went
              wrong... <br />
              Please try again or contact us on Discord.
            </p>
            <p className="mt-3">
              <CustomButton onClick={() => window.location.reload()}>
                <i className="fa fa-chevron-left mr-0-5" /> Try again
              </CustomButton>
            </p>
            <p className="mt-3 mt-1 muted">or</p>
            <p className="mt-3">
              <CustomButton href="/">
                <i className="iconcustom mr-0-5" /> Continue without code
              </CustomButton>
            </p>
          </div>
        </div>
      </>
    );
  }

  return <></>;
};

export default AffiliateLanding;
