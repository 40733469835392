import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { progressBarAtom } from '../../atoms';
import authManager from '../common/authManager';
import SocialLogin from '../common/SocialLogin';

const SocialCapture = (props) => {
  const [, setProgressBar] = useRecoilState(progressBarAtom);

  const verifyAccount = (connection) => {
    authManager.signinRedirect({ connection, callbackPath: '/application/callback' });
  };

  const onStepEnter = () => {
    document.title = 'MetaTope - Verify your account';
    setProgressBar('social');
  };

  useEffect(() => {
    onStepEnter();
  }, []);

  return (
    <>
      <div className="section center-align">
        <h3 className="header">Let&apos;s get started</h3>
        <p>Select one of the platforms below to verify your account.</p>
        <p className="muted">
          We&apos;re only requesting access to verify basic details like your name and
          email.
          <br />
          You can revoke at anytime.
        </p>
        <SocialLogin onSelect={verifyAccount} />
      </div>
    </>
  );
};

export default SocialCapture;
