import React, { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { Animated } from 'react-animated-css';
import { useNavigate } from 'react-router-dom';
import { userAtom, progressBarAtom, affiliateCodeAtom } from '../../atoms';
import { isValidAddress, resolveName } from '../../crypto';
import CustomButton from '../common/CustomButton';
import LoadingButton from '../common/LoadingButton';

const WalletCapture = () => {
  const navigate = useNavigate();
  const [affiliateCode] = useRecoilState(affiliateCodeAtom);
  const [, setProgressBar] = useRecoilState(progressBarAtom);
  const [user, setUser] = useRecoilState(userAtom);
  const [isValidatingEns, setIsValidatingEns] = useState(false);

  const nextStep = () => {
    if (affiliateCode && affiliateCode.shortForm) {
      return navigate('/application/confirm');
    }
    return navigate('/application/buckets');
  };

  const onStepEnter = () => {
    document.title = 'MetaTope - Your Ethereum wallet';
    setProgressBar('wallet');
  };

  useEffect(() => {
    onStepEnter();
  }, []);

  const [state, setState] = useState({
    isError: false,
  });

  const updateWalletAddress = (e) => {
    setState({ isError: false });
    setUser({ ...user, wallet: { address: e.target.value } });
    // props.update(e.target.name, e.target.value);
  };

  const skipStep = () => {
    nextStep();
  };

  const proceed = async () => {
    if (user.wallet && user.wallet.address) {
      if (isValidAddress(user.wallet.address)) {
        nextStep();
      } else {
        setIsValidatingEns(true);
        const ensName = user.wallet.address;
        const walletAddress = await resolveName(user.wallet.address);
        setIsValidatingEns(false);

        if (isValidAddress(walletAddress)) {
          setUser({ ...user, wallet: { address: walletAddress, ensName } });
          nextStep();
        } else {
          setState({ isError: true });
        }
      }
    } else {
      skipStep();
    }
  };

  return (
    <>
      <div className="section center-align">
        <h3 className="header">Please enter your wallet address</h3>
        <p>Please enter your Ethereum wallet address below.</p>
        <p className="muted">Note: this step is optional.</p>
        <div className="form-group">
          <div className="input-field">
            <input
              type="text"
              className="form-control validate"
              name="walletAddress"
              placeholder="Wallet address e.g. 0x1234.. or ENS name"
              onChange={updateWalletAddress}
              defaultValue={
                user && user.wallet ? user.wallet.ensName || user.wallet.address : ''
              }
            />
            {state.isError ? (
              <Animated>
                <span className="helper-text error-text">
                  Hmm... are you sure that address is correct?
                </span>
              </Animated>
            ) : (
              <span className="helper-text error-text" />
            )}
          </div>
          <p className="mt-5">
            {!isValidatingEns && (
              <CustomButton onClick={proceed}>
                Next <i className="fa fa-chevron-right ml-0-5" />
              </CustomButton>
            )}
            {isValidatingEns && <LoadingButton />}
          </p>
          <p className="muted">or</p>
          <p>
            <button type="button" className="text-button" onClick={() => skipStep()}>
              Click here to skip this step
            </button>
          </p>
        </div>
      </div>
    </>
  );
};

export default WalletCapture;
