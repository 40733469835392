import React from 'react';
import { SocialPlatforms } from '../../constants';

const SocialProfile = ({ user, className }) => {
  const socialPlatform = SocialPlatforms.find((x) => x.id === user.authType);

  return (
    <div className={`social-profile ${className}`}>
      <div className={`social-picture social-picture-${user.authType}`}>
        {user.authType === 'facebook' ? (
          <div className="facebook-profile-pic" />
        ) : (
          <img src={user.social.picture} alt={user.social.name} />
        )}
      </div>
      <div className="social-name">{user.social.name}</div>
      {user.social.nickname !== user.social.name && user.social.nickname !== '' && (
        <div className="social-nickname muted">@{user.social.nickname}</div>
      )}
      {socialPlatform && (
        <div className="social-verified">
          <i className="iconcustom-check" /> Verified via {socialPlatform.name}
        </div>
      )}
    </div>
  );
};

export default SocialProfile;
