import React from 'react';
import { SocialPlatforms } from '../../constants';

const SocialLogin = ({ onSelect }) => {
  return (
    <>
      <div className="row w85 mt-3">
        {SocialPlatforms.map((socialPlatform) => {
          return (
            <div key={socialPlatform.id} className="col s6 m3 mb-2">
              <button
                type="button"
                className="radio-button social-button"
                onClick={() => onSelect(socialPlatform.auth.connection)}
              >
                <i className="fa fa-check" />
                <i className={socialPlatform.iconClass} />
                <span>{socialPlatform.name}</span>
              </button>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default SocialLogin;
