// Libraries
import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { progressBarAtom, userAtom } from '../../atoms';
import authManager from '../common/authManager';
import Loading from '../common/Loading';
import ErrorPanel from '../common/ErrorPanel';
import SocialProfile from '../common/SocialProfile';
import CustomButton from '../common/CustomButton';

const SocialConfirm = () => {
  const navigate = useNavigate();
  const [user, setUser] = useRecoilState(userAtom);
  const [, setProgressBar] = useRecoilState(progressBarAtom);

  const onStepEnter = () => {
    document.title = 'MetaTope - Account verified';
    setProgressBar('email');
  };

  const goToNextStep = () => {
    navigate('/application/email');
  };

  const goBack = () => {
    navigate('/application/social');
  };

  useEffect(() => {
    onStepEnter();
  }, []);

  return (
    <>
      <div className="container">
        <div className="section center-align">
          <h3 className="header">
            <i className="fa fa-check mr-0-5" /> Account verified
          </h3>
          <SocialProfile className="mt-4 mb-3" user={user} />
          <p className="mt-5">
            <CustomButton onClick={goToNextStep}>
              Continue <i className="fa fa-chevron-right ml-0-5" />
            </CustomButton>
          </p>
          <p className="muted">or</p>
          <p>
            <button type="button" className="text-button" onClick={() => goBack()}>
              <i className="fa fa-chevron-left" /> Choose a different platform
            </button>
          </p>
        </div>
      </div>
    </>
  );
};

export default SocialConfirm;
