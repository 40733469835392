import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { userAtom, progressBarAtom } from '../../atoms';
import { DiscordUrl, PremintUrl } from '../../constants';
import CustomButton from '../common/CustomButton';
import SocialProfile from '../common/SocialProfile';
import SubmitChecklist from '../common/SubmitChecklist';
import TweetButton from '../common/TweetButton';

const Finished = () => {
  const [user, setUser] = useRecoilState(userAtom);
  const [, setProgressBar] = useRecoilState(progressBarAtom);

  const onStepEnter = () => {
    document.title = 'MetaTope - Application Submitted';
    setProgressBar('done');
    setUser({ ...user, hasSubmitted: true });
  };

  useEffect(() => {
    onStepEnter();
  }, []);

  if (user.interestInPresale) {
    return (
      <div className="section center-align">
        <h3 className="header">Thank you!</h3>
        <p>We have received your application.</p>
        <SocialProfile className="mt-3 mb-3" user={user} />
        <TweetButton className="mb-2" />
        <SubmitChecklist isPresaleDone={!!user.premint} />
        <hr />
        <p className="mt-4">Don&apos;t forget to join us on our Discord channel!</p>
        <CustomButton href={DiscordUrl} target="_blank" className="mt-2">
          <i className="iconcustom-discord mr-0-5" />
          Join Discord
        </CustomButton>
      </div>
    );
  }

  return (
    <div className="section center-align">
      <h3 className="header">Congratulations!</h3>
      <SocialProfile className="mt-3 mb-3" user={user} />
      <p className="mb-3 submit-success">
        <i className="fa fa-check-circle" /> Your application has been submitted!
      </p>
      <TweetButton className="mb-2" />
      <CustomButton href="/check">Check application status</CustomButton>
      <hr />
      <p>
        If you haven&apos;t already registered for the MetaTope presale, please click
        below.
      </p>
      <CustomButton href={PremintUrl} target="_blank" className="mt-2">
        <i className="iconcustom-external-link mr-0-5" />
        premint.xyz
      </CustomButton>
      <p className="mt-4">And don&apos;t forget to join us on our Discord channel!</p>

      <CustomButton href={DiscordUrl} target="_blank" className="mt-2">
        <i className="iconcustom-discord mr-0-5" />
        Join Discord
      </CustomButton>
    </div>
  );
};

export default Finished;
