// Libraries
import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import Logo from '../components/common/Logo';
import Loading from '../components/common/Loading';
import ErrorPanel from '../components/common/ErrorPanel';
import authManager from '../components/common/authManager';
import SocialLogin from '../components/common/SocialLogin';
import CheckBar from '../components/common/CheckBar';
import SocialProfile from '../components/common/SocialProfile';
import { userAtom } from '../atoms';
import CustomButton from '../components/common/CustomButton';
import SubmitChecklist from '../components/common/SubmitChecklist';

const { REACT_APP_BACKEND_API_URL } = process.env;

const ApplicationStatus = {
  pending: 'Pending',
  approved: 'Approved',
  rejected: 'Rejected',
};

const Check = () => {
  const [user, setUser] = useRecoilState(userAtom);
  const navigate = useNavigate();

  const startApplication = async () => {
    const authUser = await authManager.getUser();

    // twitter hack
    const userId = authUser.profile.sub.startsWith('oauth2|twitter-oauth2|')
      ? authUser.profile.sub.replace('oauth2|twitter-oauth2|', 'twitter|')
      : authUser.profile.sub;

    setUser({
      userId,
      authType: authUser.profile.sub
        .replace('-oauth2', '')
        .replace('oauth2|', '')
        .replace('-custom', '')
        .split('|')[0],
      social: authUser.profile,
    });
    navigate('/application/social/confirm');
  };

  const [state, setState] = useState({
    isLoading: true,
    isApplicationFound: false,
    isTwitterTokenActive: false,
    isError: false,
    isIncomplete: false,
  });

  const verifyAccount = (connection) => {
    authManager.signinRedirect({ connection, callbackPath: '/check/callback' });
  };

  const getApplicationState = async () => {
    const accessToken = (await authManager.getUser()).access_token;

    // get application from api
    let response;
    let isError = false;

    try {
      response = await axios.get(
        new URL('/signup/check', REACT_APP_BACKEND_API_URL).href,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    } catch (err) {
      isError = true;
      console.error(err);
    }

    if (isError) {
      return {
        isLoading: false,
        isError: true,
        isTwitterTokenActive: false,
        isApplicationFound: false,
      };
    }

    if (response.data.isSuccessful) {
      return {
        isLoading: false,
        isApplicationFound: true,
        isTwitterTokenActive: true,
        isError: false,
        application: response.data.application,
        isIncomplete:
          response.data.application.status === 'pending' &&
          (!response.data.application.categories ||
            response.data.application.categories.length === 0),
      };
    }

    return {
      isLoading: false,
      isApplicationFound: response.data.error !== 'NO_APPLICATION_FOUND',
      isTwitterTokenActive: response.data.error !== 'INVALID_TWITTER_TOKEN',
      isError: false,
    };
  };

  const onViewLoad = async () => {
    document.title = 'MetaTope - Check your application status';
    const authUser = await authManager.getUser();

    if (authUser) {
      const applicationState = await getApplicationState();
      setState(applicationState);

      // twitter hack
      const userId = authUser.profile.sub.startsWith('oauth2|twitter-oauth2|')
        ? authUser.profile.sub.replace('oauth2|twitter-oauth2|', 'twitter|')
        : authUser.profile.sub;

      setUser({
        userId,
        authType: authUser.profile.sub
          .replace('-oauth2', '')
          .replace('oauth2|', '')
          .replace('-custom', '')
          .split('|')[0],
        social: authUser.profile,
      });
    } else {
      setState({
        isLoading: false,
        isError: false,
        isTwitterTokenActive: false,
      });
    }
  };

  const resumeApp = () => {
    // set user from state (api response)
    setUser(state.application);
    navigate('/application/social/confirm');
  };

  let isLoaded = false;
  useEffect(() => {
    if (!isLoaded) {
      onViewLoad();
    }
    isLoaded = true;
  }, []);

  if (state.isLoading) {
    return (
      <>
        <CheckBar />
        <div className="container">
          <Logo />
          <div className="section center-align">
            <h3 className="header">Please wait...</h3>
            <p>Verifying your account...</p>
            <Loading />
          </div>
        </div>
      </>
    );
  }

  if (state.isError) {
    return (
      <>
        <CheckBar />
        <div className="container">
          <Logo />
          <ErrorPanel />
        </div>
      </>
    );
  }

  if (!state.isTwitterTokenActive) {
    return (
      <>
        <CheckBar />
        <div className="container">
          <Logo />
          <div className="section center-align">
            <h3 className="header">Verify your account</h3>
            <p>
              To check your application status, please select the platform that you used
              to submit your application.
            </p>
            <SocialLogin onSelect={verifyAccount} />
          </div>
        </div>
      </>
    );
  }

  if (!state.isApplicationFound) {
    return (
      <>
        <CheckBar />
        <div className="container center-align">
          <Logo />
          <div className="section center-align">
            <div className="divider-large mt-5" />
            <SocialProfile className="mt-3 mb-3" user={user} />
            <hr className="hr-light mb-5 mt-3" />
            <h3 className="header">Whoops!</h3>
            <p>
              Looks like you haven&apos;t submitted an application. Click below to start a
              new application.
            </p>
            <p className="mt-3">
              <CustomButton onClick={startApplication}>
                Apply in 60 seconds <i className="fa fa-chevron-right ml-0-5" />
              </CustomButton>
            </p>
          </div>
          <div className="divider-large mt-5 mb-3" />
          <CustomButton href="/logout">
            <i className="fa fa-sign-out-alt mr-0-5" /> Logout
          </CustomButton>
        </div>
      </>
    );
  }

  return (
    <>
      <CheckBar />
      <div className="container">
        <Logo />
        <div className="section center-align">
          <h3 className="header">Application Status</h3>
          <div className="divider-large mt-5" />
          <SocialProfile className="mt-3 mb-3" user={state.application} />
          <hr className="hr-light mb-5 mt-3" />

          <>
            <p className="mt-3 mb-2">Your application status is:</p>
            <p>
              {!state.isIncomplete && (
                <div className={`status-pill status-pill-${state.application.status}`}>
                  {ApplicationStatus[state.application.status]}
                </div>
              )}
              {state.isIncomplete && (
                <div className="status-pill status-pill-pending">Incomplete</div>
              )}
            </p>
          </>

          {state.isIncomplete && (
            <>
              <p className="mt-3">Please click below to resume your application.</p>
              <p className="mt-3">
                <CustomButton onClick={resumeApp}>
                  Resume Application <i className="fa fa-chevron-right ml-0-5" />
                </CustomButton>
              </p>
            </>
          )}
          {!state.isIncomplete && state.application.status === 'pending' && (
            <p className="mt-3">Please check back soon!</p>
          )}

          {state.application.comments && state.application.comments !== '' && (
            <>
              <p className="mt-4">
                <strong>Comments from MetaTope:</strong>
              </p>
              <p>{state.application.comments}</p>
            </>
          )}
          {state.application.interestInPresale && !state.isIncomplete && (
            <>
              <hr className="hr-light mb-5 mt-3" />
              <SubmitChecklist isPresaleDone={!!state.application.premint} />
            </>
          )}
          <div className="divider-large mt-5 mb-3" />
          <CustomButton href="/logout">
            <i className="fa fa-sign-out-alt mr-0-5" /> Logout
          </CustomButton>
        </div>
      </div>
    </>
  );
};

export default Check;
