import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { progressBarAtom, stepAtom, userAtom } from '../../../atoms';
import CustomButton from '../../common/CustomButton';

const WhyYouCapture = () => {
  const navigate = useNavigate();
  const [user, setUser] = useRecoilState(userAtom);
  const [, setProgressBar] = useRecoilState(progressBarAtom);
  const [state, setState] = useState({
    answer: (user && user.answerWhyYou) ?? '',
    isError: false,
  });

  const nextStep = () => {
    navigate('/application/links');
  };

  const onStepEnter = () => {
    document.title = 'MetaTope - Why should we choose you?';
    setProgressBar('questions');
  };

  const updateAnswer = (e) => {
    setState({ answer: e.target.value, isError: false });
  };

  const goToNextStep = () => {
    if (state.answer !== '') {
      setUser({ ...user, answerWhyYou: state.answer });
      nextStep();
    } else {
      setState({ ...state, isError: true });
    }
  };

  useEffect(() => {
    onStepEnter();
  }, []);

  return (
    <>
      <div className="section center-align">
        <h4 className="sub-header">Question 4 of 5</h4>
        <h3 className="header">Why should we choose you?</h3>
        <p className="mb-3">Tell us in a few words.</p>
        <p>
          <textarea onChange={updateAnswer} defaultValue={user.answerWhyYou} />
          {state.isError ? (
            <span className="helper-text error-text">
              Please answer the question above to continue
            </span>
          ) : (
            <span className="helper-text error-text">&nbsp;</span>
          )}
        </p>
        <p className="mt-3">
          <CustomButton onClick={goToNextStep}>
            Next <i className="fa fa-chevron-right ml-0-5" />
          </CustomButton>
        </p>
      </div>
    </>
  );
};

export default WhyYouCapture;
