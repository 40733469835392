import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { userAtom, progressBarAtom } from '../../atoms';
import { DiscordUrl, PremintUrl } from '../../constants';
import CheckBar from '../common/CheckBar';
import CustomButton from '../common/CustomButton';
import SocialProfile from '../common/SocialProfile';

const ShortAppFinished = () => {
  const [user, setUser] = useRecoilState(userAtom);
  const [, setProgressBar] = useRecoilState(progressBarAtom);

  const onStepEnter = () => {
    document.title = 'MetaTope - Thank you';
    setProgressBar('done-short');
    setUser({ ...user, hasSubmitted: true });
  };

  useEffect(() => {
    onStepEnter();
  }, []);

  return (
    <div className="section center-align">
      <h3 className="header">Thank you!</h3>
      <SocialProfile className="mt-3 mb-3" user={user} />
      <p>If you would like to be part of the MetaTope presale, please click below.</p>
      <CustomButton href={PremintUrl} target="_blank" className="mt-2">
        <i className="iconcustom-external-link mr-0-5" />
        premint.xyz
      </CustomButton>
      <hr />
      <p>Join us on our Discord channel!</p>
      <CustomButton href={DiscordUrl} target="_blank" className="mt-1">
        <i className="iconcustom-discord mr-0-5" />
        Join Discord
      </CustomButton>
    </div>
  );
};

export default ShortAppFinished;
