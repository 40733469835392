import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const TweetButton = ({ className, message }) => {
  const text = message ?? 'MetaTope @ https://metatope.com';
  useEffect(() => {
    // load twitter js
  }, []);

  return (
    <>
      {/*
      <a
        className={`twitter-share-button ${className}`}
        href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}`}
        target="_blank"
        rel="noreferrer"
      >
        <i className="iconcustom-twitter" />
        Tweet
      </a>
      */}
    </>
  );
};

export default TweetButton;
