import React from 'react';

const Logo = () => {
  return (
    <>
      <div className="logo">
        <a href="/">
          <span className="logo-image" />
          <span className="logo-text" />
        </a>
      </div>
      <hr className="mt-1 mb-1 divider-small" />
    </>
  );
};

export default Logo;
