import React from 'react';
import Loading from './Loading';

const LoadingButton = ({ children, className }) => {
  return (
    <>
      <button
        disabled
        type="button"
        className={`custom-button custom-button-loading ${className}`}
      >
        <span className="custom-button-left" />
        <span className="custom-button-middle">
          <Loading />
        </span>
        <span className="custom-button-right" />
      </button>
    </>
  );
};

export default LoadingButton;
