import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { progressBarAtom, userAtom } from '../../atoms';
import Checkbox from '../common/Checkbox';
import authManager from '../common/authManager';
import CustomButton from '../common/CustomButton';

const EmailCapture = () => {
  const navigate = useNavigate();
  const [user, setUser] = useRecoilState(userAtom);
  const [, setProgressBar] = useRecoilState(progressBarAtom);

  const [state, setState] = useState({
    isError: false,
  });

  const nextStep = () => {
    navigate('/application/interests');
  };

  const isValidEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const updateEmail = (e) => {
    const email = e.target.value;
    setUser({ ...user, email });
    if (isValidEmail(email)) {
      setState({ isError: false });
    }
  };

  const goToNextStep = () => {
    if (!isValidEmail(user.email)) {
      setState({ isError: true });
    } else {
      nextStep();
    }
  };

  const onStepEnter = async () => {
    document.title = 'MetaTope - Your email';

    if (!user.email) {
      // try pre fill from social profile
      const authUser = await authManager.getUser();

      if (authUser && authUser.profile && authUser.profile.email) {
        const { email } = authUser.profile;
        setUser({ ...user, email, isEmailPrefilled: true });
      }
    }

    setProgressBar('email');
  };

  const clickEmailOptIn = (interestInEmail) => {
    setUser({ ...user, interestInEmail });
  };

  useEffect(() => {
    onStepEnter();
  }, []);

  return (
    <>
      <div className="section center-align">
        {user.isEmailPrefilled ? (
          <>
            <h3 className="header">Is this your email?</h3>
            <p>To continue, please verify your email address.</p>
          </>
        ) : (
          <>
            <h3 className="header">Please enter your email</h3>
            <p>To continue, please enter your email address.</p>
          </>
        )}
        <div className="form-group">
          <input
            type="email"
            className="form-control"
            name="emailAddress"
            onChange={updateEmail}
            defaultValue={user && user.email ? user.email : ''}
          />

          <div className="mt-2 mb-2">
            <Checkbox onClick={clickEmailOptIn} checked={user && user.interestInEmail}>
              Keep me up to date with MetaTope&apos;s latest news and updates
            </Checkbox>
          </div>
          {state.isError ? (
            <span className="helper-text error-text">
              Hmm... are you sure that email address is correct?
            </span>
          ) : (
            <span className="helper-text error-text">&nbsp;</span>
          )}

          <p className="mt-2">
            <CustomButton onClick={goToNextStep}>
              Next <i className="fa fa-chevron-right ml-0-5" />
            </CustomButton>
          </p>
        </div>
      </div>
    </>
  );
};
export default EmailCapture;
