import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { affiliateCodeAtom, progressBarAtom, userAtom } from '../../atoms';
import { DiscordUrl } from '../../constants';
import Loading from '../common/Loading';
import { upsertUser } from '../../integration';
import CustomButton from '../common/CustomButton';

const YesNoRadioButtons = ({ value, onSelected }) => {
  const [state, setState] = useState(value);

  const selectValue = (val) => {
    setState(val);
    onSelected(val);
  };

  return (
    <div className="mt-2">
      <button
        type="button"
        className={`mr-1 radio-button yes-no-radio-button ${
          state === true ? 'selected' : ''
        }`}
        onClick={() => selectValue(true)}
      >
        <i className="fa fa-check" />
        Yes
      </button>
      <button
        type="button"
        className={`radio-button yes-no-radio-button ${
          state === false ? 'selected' : ''
        }`}
        onClick={() => selectValue(false)}
      >
        <i className="fa fa-check" />
        No
      </button>
    </div>
  );
};

const InterestCapture = () => {
  const navigate = useNavigate();
  const [user, setUser] = useRecoilState(userAtom);
  const [affiliateCode] = useRecoilState(affiliateCodeAtom);
  const [, setProgressBar] = useRecoilState(progressBarAtom);

  const [state, setState] = useState({
    isValidationError: false,
    isSaving: false,
    isError: false,
  });

  const tryAgain = () => {
    setState({
      isSaving: false,
      isError: false,
    });
  };

  const nextStep = () => {
    if (user.interestInMobile) {
      return navigate('/application/wallet');
    }

    return navigate('/application/finished-short-app');
  };

  const isValidForm = () => {
    if (user.interestInMobile !== true && user.interestInMobile !== false) {
      return false;
    }
    if (user.interestInPresale !== true && user.interestInPresale !== false) {
      return false;
    }
    return true;
  };

  const goToNextStep = async () => {
    if (!isValidForm()) {
      return setState({ isValidationError: true });
    }
    // if short form, dont bother saving, go to next step
    if (affiliateCode && affiliateCode.shortForm) {
      return navigate('/application/wallet');
    }

    setState({ isValidationError: false, isSaving: true });

    // save user details via api
    let dbUser;
    try {
      dbUser = await upsertUser(user);
    } catch (err) {
      setState({ isError: true });
      console.error(err);
    }

    if (dbUser) {
      setUser({ ...dbUser });
      return nextStep();
    }

    return undefined;
  };

  const onStepEnter = () => {
    document.title = 'MetaTope - Your interests';
    setProgressBar('email');
  };

  useEffect(() => {
    onStepEnter();
  }, []);

  const selectInterestInMobile = (val) => {
    setUser({ ...user, interestInMobile: val });
    setState({ isValidationError: false });
  };

  const selectInterestInPresale = (val) => {
    setUser({ ...user, interestInPresale: val });
    setState({ isValidationError: false });
  };

  if (state.isSaving) {
    return (
      <div className="section center-align">
        <h3 className="header">Please wait...</h3>
        <p>Updating your details...</p>
        <Loading />
      </div>
    );
  }

  if (state.isError) {
    return (
      <div className="section center-align">
        <h3 className="header">Whoops!</h3>
        <p>
          Looks like something went wrong... Please try again or contact us on Discord.
        </p>
        <p className="mt-3">
          <CustomButton onClick={tryAgain}>
            <i className="fa fa-chevron-left mr-0-5" /> Try again
          </CustomButton>
        </p>
        <p className="mt-3 mt-1 muted">or</p>
        <p className="mt-3">
          <CustomButton href={DiscordUrl}>
            <i className="iconcustom mr-0-5" /> Contact us
          </CustomButton>
        </p>
      </div>
    );
  }

  return (
    <>
      <div className="section center-align">
        <h3 className="header">Are you interested in...</h3>
        <p className="mt-3 mb-1">Early access to the MetaTope mobile app?</p>
        <YesNoRadioButtons
          value={user.interestInMobile}
          onSelected={selectInterestInMobile}
        />
        <p className="mt-4 mb-1">MTskin Presale?</p>
        <YesNoRadioButtons
          value={user.interestInPresale}
          onSelected={selectInterestInPresale}
        />
        <div className="mt-3">
          {state.isValidationError ? (
            <span className="helper-text error-text">
              Whoops.. make sure you answer the above questions to continue.
            </span>
          ) : (
            <span className="helper-text error-text">&nbsp;</span>
          )}
          <p className="mt-2">
            <CustomButton onClick={goToNextStep}>
              Next <i className="fa fa-chevron-right ml-0-5" />
            </CustomButton>
          </p>
        </div>
      </div>
    </>
  );
};

export default InterestCapture;
