export const WebsiteName = 'MetaTope';
export const WebsiteUrl = 'https://www.metatope.com';
export const DiscordUrl = 'https://discord.gg/metatope';
export const TwitterUrl = 'https://twitter.com/themetatope';
export const InstagramUrl = 'https://www.instagram.com/themetatope/';
export const TiktokUrl = 'https://vm.tiktok.com/TTPdyW22NL/';
export const YoutubeUrl = 'https://www.youtube.com/channel/UClzrTmWonE6RsNddDZ6LL0g';
export const PremintUrl = 'https://premint.xyz/metatope';

export const SocialPlatforms = [
  {
    id: 'twitter',
    name: 'Twitter',
    iconClass: 'iconcustom-twitter',
    auth: { connection: 'twitter' }, // custom = twitter-oauth2, ootb = twitter
  },
  {
    id: 'google',
    name: 'Google',
    iconClass: 'iconcustom-google',
    auth: { connection: 'google-oauth2' },
  },
  /*
  {
    id: 'facebook',
    name: 'Facebook',
    iconClass: 'iconcustom-facebook',
    auth: { connection: 'facebook' },
  },
  */
  {
    id: 'twitch',
    name: 'Twitch',
    iconClass: 'iconcustom-twitch',
    auth: { connection: 'twitch-custom' },
  },
  {
    id: 'discord',
    name: 'Discord',
    iconClass: 'iconcustom-discord',
    auth: { connection: 'discord' },
  },
];

export const Categories = [
  {
    id: 'enthusiast',
    title: 'Enthusiast',
    description: 'Collector, investor or degen.',
  },
  {
    id: 'builder',
    title: 'Founder/Builder',
    description: 'Disruptor, visionary or entrepreneur.',
  },
  {
    id: 'project',
    title: 'Web3 Project Member',
    description: 'Team member of metaverse, gaming, PFP, or other project.',
  },
  {
    id: 'content-creator',
    title: 'Content Creator',
    description:
      'Passionate about creating content such as videos, streams, threads, etc for social platforms.',
  },
  {
    id: 'creative',
    title: 'Creative',
    description: 'Artist, designer, filmmaker, musician, animator, etc.',
  },
  {
    id: 'other',
    title: 'Other',
    description: 'Developer, VIP, gamers, fashionista or something else.',
  },
];

export const NftTypes = [
  {
    id: 'bayc',
    title: 'BAYC',
    chain: 'Ethereum',
  },
  {
    id: 'mayc',
    title: 'MAYC',
    chain: 'Ethereum',
  },
  {
    id: 'cryptopunks',
    title: 'CryptoPunks',
    chain: 'Ethereum',
  },
  {
    id: 'clonex',
    title: 'CloneX',
    chain: 'Ethereum',
  },
  {
    id: 'doodles',
    title: 'Doodles',
    chain: 'Ethereum',
  },
  {
    id: 'azuki',
    title: 'Azuki',
    chain: 'Ethereum',
  },
  {
    id: 'degods',
    title: 'DeGods',
    chain: 'Solana',
  },
  {
    id: 'yoots',
    title: 'y000ts',
    chain: 'Solana',
  },
  {
    id: 'spacebudz',
    title: 'Spacebudz',
    chain: 'Cardano',
  },
  {
    id: 'clay-nation',
    title: 'Clay Nation',
    chain: 'Cardano',
  },
  {
    id: 'ape-society',
    title: 'Ape Society',
    chain: 'Cardano',
  },
];

export default WebsiteName;
