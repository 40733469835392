// Libraries
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { ToastContainer } from 'react-toastify';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

// Views
import Application from './views/Application';
import Landing from './views/Landing';

// CSS
import 'animate.css';
import './assets/sass/app.scss';

// Base components
import Footer from './components/common/Footer';
import Check from './views/Check';
import CheckCallback from './views/CheckCallback';
import Logout from './views/Logout';
import AffiliateLanding from './views/AffiliateLanding';

const GoogleAnalyticsId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
ReactGA.initialize(GoogleAnalyticsId);

// below are vanity redirect urls
const gaTrackingExemptList = [
  '/application',
  '/application/enthusiast',
  '/application/builder',
  '/application/project',
  '/application/content-creator',
  '/application/creative',
  '/application/other',
  '/application/questions',
];

const RouterApp = () => {
  const [currentPath, setCurrentPath] = useState(null);
  const location = useLocation();

  const getTopFolder = (path) => {
    return path.match(/^\/\w*/)[0];
  };

  useEffect(() => {
    if (location.pathname !== currentPath) {
      // when location changes, record in GA
      if (!gaTrackingExemptList.find((x) => x === location.pathname)) {
        setCurrentPath(location.pathname);
        ReactGA.send({ hitType: 'pageview', page: location.pathname });
      }
    }
  }, [location.pathname]);

  return (
    <>
      <TransitionGroup component="div" className="App">
        <CSSTransition
          key={getTopFolder(location.pathname)}
          timeout={{ enter: 800, exit: 400 }}
          classNames="fade"
          mountOnEnter={false}
          unmountOnExit
        >
          <div>
            <Routes>
              <Route exact path="/" element={<Landing />} />
              <Route exact path="/application/*" element={<Application />} />
              <Route exact path="/check" element={<Check />} />
              <Route exact path="/check/callback" element={<CheckCallback />} />
              <Route exact path="/logout" element={<Logout />} />
              <Route path="/:urlAffiliateCode" element={<AffiliateLanding />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </div>
        </CSSTransition>
      </TransitionGroup>
    </>
  );
};

const App = () => {
  let isLoaded = false;
  useEffect(() => {
    if (!isLoaded) {
      // once first loaded
    }
    isLoaded = true;
  }, []);

  return (
    <div className="main-container">
      <ToastContainer />
      <Router>
        <RouterApp />
      </Router>
      <Footer />
    </div>
  );
};

export default App;
