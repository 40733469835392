import { ethers, providers } from 'ethers';
import { BigNumber } from 'bignumber.js';
import Authereum from 'authereum';
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
import WalletConnectProvider from '@walletconnect/web3-provider';
import Web3Modal from 'web3modal';
import { showToastError, showToastInfo } from './components/common/toast';
import { isMetamaskApp } from './helpers';

export const RPC_URL = process.env.REACT_APP_RPC_URL;
export const CHAIN_ID = parseInt(process.env.REACT_APP_CHAIN_ID, 10);

const providerOptions = {
  /*
  authereum: {
    package: Authereum,
  },
  coinbasewallet: {
    package: CoinbaseWalletSDK,
    options: {
      appName: 'Coinbase Wallet',
      rpc: {
        CHAIN_ID: RPC_URL,
      },
    },
  },
  */
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      rpc: {
        CHAIN_ID: RPC_URL,
      },
    },
  },
};

const web3Modal = new Web3Modal({
  theme: 'dark',
  cacheProvider: false,
  providerOptions,
});

/*
// Setup Metamask
const web3provider = !window.ethereum
  ? null
  : new ethers.providers.Web3Provider(window.ethereum, CHAIN_ID);
*/

let web3provider;

export const rpcProvider = new ethers.providers.JsonRpcProvider(RPC_URL);

export const formatNumberString = (number) => {
  if (number === null || number === undefined) return number;

  const num = new BigNumber(number.toString());

  // use 4 decimal place if number is less than 10
  let decimals = num.lt(10) ? 4 : 2;
  const numStr = num.toString();

  // if number less than 0, show decimals up until its no longer zero
  if (num.lt(1) && numStr.indexOf('.') > 0) {
    const decimalStr = numStr.split('.')[1];
    if (decimalStr > 2) {
      const match = decimalStr.match(/^0+/);
      const leadingZeros = match ? match[0].length : 0;
      decimals += leadingZeros;
    }
  }

  return num.toFormat(decimals);
};

export const formatNumber = (number) =>
  new BigNumber(number.toString()).div(10 ** 18).toNumber();

export const isValidAddress = (address) => ethers.utils.isAddress(address);

export const getBalance = async (address) => {
  const balance = await rpcProvider.getBalance(address);
  return formatNumber(balance);
};

export const getConnectedWalletAddress = async () => {
  if (!web3provider) {
    return null;
  }

  let walletAddress;
  const signer = web3provider.getSigner();

  try {
    walletAddress = await signer.getAddress();
  } catch {
    // do nothing
  }

  return walletAddress;
};

export const disconnectWallet = async () => {
  if (window) {
    // clear all local storage
    window.localStorage.clear();
  }
  if (web3provider && web3provider.close) {
    await web3provider.close();
  }

  await web3Modal.clearCachedProvider();
  window.web3selectedProvider = null;
  web3provider = null;
};

export const isMetamaskSelected = () => {
  return window.web3selectedProvider === 'MetaMask';
};

export const switchWallet = async () => {
  if (isMetamaskApp) {
    showToastInfo(
      'Please select a different wallet by pressing the button in the top right.'
    );
  } else if (isMetamaskSelected()) {
    // open meta mask wallet
    try {
      await web3provider.send('wallet_requestPermissions', [{ eth_accounts: {} }]);
    } catch (error) {
      console.error(error);
      if (error.code !== 4001) {
        showToastError(
          'Metamask requires your input. Please open MetaMask to continue...'
        );
      }
    }
  } else {
    showToastInfo('Please open your wallet app to select a different wallet');
  }
};

export const openWallet = async () => {
  await disconnectWallet();

  let instance;
  let error;
  try {
    instance = await web3Modal.connect();
  } catch (_error) {
    error = _error;
  }

  if (instance) {
    web3provider = new ethers.providers.Web3Provider(instance);
  }

  if (error) {
    if (isMetamaskSelected()) {
      showToastError('Metamask requires your input. Please open MetaMask to continue...');
    }
  }

  /*
  if (!web3provider) {
    showToastError(
      "Whoops! Looks like you don't have a wallet installed. Please install a wallet, e.g. MetaMask to continue."
    );
    return;
  }
  */

  const walletAddress = await getConnectedWalletAddress();

  // eslint-disable-next-line consistent-return
  return walletAddress;
};

export const signMessage = async (message) => {
  const signer = web3provider.getSigner();
  const signature = await signer.signMessage(message);
  return signature;
};

export const resolveName = async (name) => {
  return rpcProvider.resolveName(name);
};

document.onclick = (e) => {
  const clickedElement = window.event ? window.event.srcElement : e.target;
  const web3modalContainer = clickedElement.closest('.web3modal-provider-container');

  if (web3modalContainer) {
    window.web3selectedProvider = web3modalContainer.querySelector(
      '.web3modal-provider-name'
    ).innerText;
  }
};
