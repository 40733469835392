// Libraries
import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { progressBarAtom, userAtom } from '../../atoms';
import authManager from '../common/authManager';
import Loading from '../common/Loading';
import ErrorPanel from '../common/ErrorPanel';
import SocialProfile from '../common/SocialProfile';
import { DiscordUrl } from '../../constants';
import CustomButton from '../common/CustomButton';

const AuthCallback = () => {
  const navigate = useNavigate();
  const [user, setUser] = useRecoilState(userAtom);
  const [, setProgressBar] = useRecoilState(progressBarAtom);
  const [state, setState] = useState({
    isError: false,
    isLoading: true,
  });

  const doCallback = async () => {
    setProgressBar('social');

    // check for ?error in url
    const error = new URLSearchParams(window.location.search).get('error');
    if (error) {
      setState({ isError: true });
      return;
    }

    let authUser;
    try {
      // exchange auth code for access/refresh token
      authUser = await authManager.signinRedirectCallback({
        callbackPath: '/application/callback',
      });
    } catch (e) {
      console.error(e);
    }

    // if refresh page, try get existing user
    if (!authUser) {
      authUser = await authManager.getUser();
    }

    if (authUser) {
      // twitter hack
      const userId = authUser.profile.sub.startsWith('oauth2|twitter-oauth2|')
        ? authUser.profile.sub.replace('oauth2|twitter-oauth2|', 'twitter|')
        : authUser.profile.sub;

      setUser({
        ...user,
        userId,
        authType: authUser.profile.sub
          .replace('-oauth2', '')
          .replace('oauth2|', '')
          .replace('-custom', '')
          .split('|')[0],
        social: authUser.profile,
      });
      navigate('/application/social/confirm', { replace: true });
    } else {
      setState({ isError: true });
    }
  };

  let isLoaded = false;
  useEffect(() => {
    if (!isLoaded) {
      doCallback();
    }
    isLoaded = true;
  }, []);

  if (state.isError) {
    return (
      <>
        <div className="container">
          <div className="section center-align">
            <h3 className="header">Whoops!</h3>
            <p>
              Looks like something went wrong... Please try again or contact us on
              Discord.
            </p>
            <p className="mt-3">
              <CustomButton href="/application/social">
                <i className="fa fa-chevron-left mr-0-5" /> Try again
              </CustomButton>
            </p>
            <p className="muted">or</p>
            <p>
              <a type="button" className="text-button" href={DiscordUrl}>
                <i className="iconcustom-discord icon-15" /> Contact us on Discord
              </a>
            </p>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="container">
        <div className="section center-align">
          <h3 className="header">Please wait...</h3>
          <p>Verifying your account...</p>
          <Loading />
        </div>
      </div>
    </>
  );
};

export default AuthCallback;
