import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { affiliateCodeAtom, userAtom } from '../../atoms';
import CheckBar from './CheckBar';

const ProgressBar = ({ activeStep, allDone }) => {
  const [affiliateCode] = useRecoilState(affiliateCodeAtom);
  const [user] = useRecoilState(userAtom);
  const navigate = useNavigate();

  const defaultSteps = [
    { id: 'social', label: 'Social', path: '/application/social/confirm' },
    { id: 'email', label: 'Email', path: '/application/email' },
    { id: 'wallet', label: 'Wallet', path: '/application/wallet' },
    { id: 'categories', label: 'Buckets', path: '/application/buckets' },
    { id: 'questions', label: 'Questions' },
  ];

  const shortSteps = [
    { id: 'social', label: 'Social', path: '/application/social/confirm' },
    { id: 'email', label: 'Email', path: '/application/email' },
    { id: 'wallet', label: 'Wallet', path: '/application/wallet' },
  ];

  const steps = affiliateCode && affiliateCode.shortForm ? shortSteps : defaultSteps;
  const activeStepIndex = steps.findIndex((x) => x.id === activeStep);

  const isStepComplete = (step) => {
    if (activeStep === 'done') {
      return true;
    }

    const stepIndex = steps.findIndex((x) => x.id === step.id);
    return stepIndex < activeStepIndex;
  };

  const clickStep = (step) => {
    if (isStepComplete(step)) {
      if (step.id === 'questions') {
        navigate(`/application/${user.categories[0]}`);
      } else {
        navigate(step.path);
      }
    }
  };

  if (activeStep === 'done-short') {
    return (
      <>
        <CheckBar forceGoBack />
      </>
    );
  }

  return (
    <div className="progressbar-container">
      <div className="progressbar-wrapper">
        <ul className="progressbar">
          {steps.map((step, i) => {
            let className = '';
            if (i < activeStepIndex) className = 'complete';
            if (i === activeStepIndex) className = 'active';
            if (activeStep === 'done') className = 'complete';

            return (
              <li
                key={step.id}
                className={className}
                role="none"
                onClick={() => clickStep(step)}
              >
                {step.label}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default ProgressBar;
