import React from 'react';
import { useNavigate } from 'react-router-dom';
import CheckBar from '../components/common/CheckBar';
import CustomButton from '../components/common/CustomButton';

import Logo from '../components/common/Logo';
import heroImage from '../assets/img/landing_hero.png';
import videoImage from '../assets/img/landing_video.jpg';

const Landing = () => {
  const navigate = useNavigate();

  const startApplication = () => {
    navigate('/application');
  };

  return (
    <>
      <CheckBar />
      <div className="container">
        <Logo />
        <div className="section center-align">
          <h3 className="header">Apply today</h3>
          <p className="mb-3">
            We&apos;re building a community of the coolest people in Web3.
          </p>
          <CustomButton onClick={startApplication}>
            Apply in 60 seconds <i className="fa fa-chevron-right" />
          </CustomButton>
        </div>
        <div className="mt-2 divider-large" />
        <div>
          <img src={heroImage} alt="" className="landing-img-large" />
        </div>
        <div className="section center-align">
          <p>
            Apply to get early access to our cutting edge technology and to participate in
            its further development.
          </p>
          <p>Accepted applicants will also get priority access to our presale.</p>
          <p>
            <h3 className="header-small">
              Additional perks for eligible applicants include:
            </h3>
          </p>
          <p>
            Exclusive Access to Alpha Channels &nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;
            Discord Roles &nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp; IRL Events and Experiences
            Exclusive Giveaways and
            Airdrops&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;Eligibility to win a free
            mint&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;Private Discord Calls Eligible for
            Exclusive Merch&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;And More!
          </p>
        </div>
        <div className="mt-4 mb-4">
          <img src={videoImage} alt="" className="landing-img" />
        </div>
        <div className="logo-triangle" />
        <div className="section center-align">
          <h3 className="header">How it works</h3>
          <p>
            A simple & secure way for people to get access to the most exciting mints -
            based on merit.
          </p>
        </div>
        <div className="section center-align">
          <div className="row">
            <div className="col s6 m2 mb-1" />
            {/*
            <div className="col s12 m4 mb-2">
              <div className="landing-icon wallet" />
              <p>
                <strong>Connect your wallet</strong>
              </p>
              <p className="muted mr-1 ml-1">
                If accepted, we will whitelist your Ethereum wallet address.
              </p>
            </div>
          */}
            <div className="col s12 m4 mb-2">
              <div className="landing-icon social" />
              <p>
                <strong>Verify you are human</strong>
              </p>
              <p className="muted mr-1 ml-1">
                You connect one of your social profiles to verify that you are human.
              </p>
            </div>
            <div className="col s12 m4 mb-2">
              <div className="landing-icon questions" />
              <p>
                <strong>Answer a few questions</strong>
              </p>
              <p className="muted mr-1 ml-1">
                Choose which category you fit in. Answer a few questions about yourself.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Landing;
