// Libraries
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Logo from '../components/common/Logo';
import Loading from '../components/common/Loading';
import ErrorPanel from '../components/common/ErrorPanel';
import authManager from '../components/common/authManager';
import CheckBar from '../components/common/CheckBar';

const CheckCallback = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    isError: false,
  });

  const doCallback = async () => {
    try {
      // exchange auth code for access/refresh token
      await authManager.signinRedirectCallback({ callbackPath: '/check/callback' });
    } catch (e) {
      console.error(e);
    }

    const authUser = await authManager.getUser();

    if (authUser) {
      navigate('/check');
    } else {
      setState({ isError: true });
    }
  };

  let isLoaded = false;
  useEffect(() => {
    if (!isLoaded) {
      doCallback();
    }
    isLoaded = true;
  }, []);

  return !state.isError ? (
    <>
      <CheckBar />
      <div className="container">
        <Logo />
        <div className="section center-align">
          <h3 className="header">Please wait...</h3>
          <p>Verifying your account...</p>
          <Loading />
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="container">
        <Logo />
        <ErrorPanel />
      </div>
    </>
  );
};

export default CheckCallback;
