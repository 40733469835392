import React, { useState } from 'react';

const Checkbox = ({ checked, onClick, children }) => {
  const defaultChecked = checked ?? false;
  const [isChecked, setIsChecked] = useState(defaultChecked);

  const click = () => {
    const newValue = !isChecked;
    setIsChecked(newValue);

    if (onClick) {
      onClick(newValue);
    }
  };

  return (
    <>
      <div role="none" className="checkbox-wrapper" onClick={click}>
        <span className={`checkbox-icon ${isChecked ? 'checked' : ''}`}>
          <i className="iconcustom-check" />
        </span>
        <span className="checkbox-text"> {children}</span>
      </div>
    </>
  );
};

export default Checkbox;
