import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { progressBarAtom, userAtom } from '../../../atoms';
import CustomButton from '../../common/CustomButton';

const FollowingOptions = ['< 10k', '10k - 50k', '50k - 250k', '250k+'];

const FollowingCapture = () => {
  const navigate = useNavigate();
  const [, setProgressBar] = useRecoilState(progressBarAtom);
  const [user, setUser] = useRecoilState(userAtom);
  const [state, setState] = useState({ isError: false });

  const onStepEnter = () => {
    document.title = 'MetaTope - What is your following?';
    setProgressBar('questions');
  };

  const nextStep = () => {
    navigate('/application/links');
  };

  useEffect(() => {
    onStepEnter();
  }, []);

  const isOptionSelected = (option) => {
    return user.answerFollowers === option;
  };

  const selectOption = async (option) => {
    setUser({ ...user, answerFollowers: option });
    setState({ isError: false });
  };

  const goToNextStep = () => {
    if (!user.answerFollowers || user.answerFollowers.length === 0) {
      setState({ isError: true });
    } else {
      nextStep();
    }
  };

  return (
    <>
      <div className="section center-align">
        <h4 className="sub-header">Question 4 of 5</h4>
        <h3 className="header">What is your following on your main platform(s)?</h3>
        <p className="mb-3">&nbsp;</p>
        <div className="row">
          {FollowingOptions.map((opt) => {
            return (
              <div key={opt} className="col s3 l3 m6 mb-2">
                <div
                  role="none"
                  className={`radio-button ${isOptionSelected(opt) ? 'selected' : ''}`}
                  onClick={() => selectOption(opt)}
                >
                  <i className="fa fa-check" />
                  <div className="category-title">{opt}</div>
                </div>
              </div>
            );
          })}
        </div>
        {state.isError ? (
          <span className="helper-text error-text">
            Whoops.. looks like you didn&apos;t select an option.
          </span>
        ) : (
          <span className="helper-text error-text">&nbsp;</span>
        )}

        <p className="mt-3">
          <CustomButton onClick={goToNextStep}>
            Next <i className="fa fa-chevron-right ml-0-5" />
          </CustomButton>
        </p>
      </div>
    </>
  );
};

export default FollowingCapture;
