// Libraries
import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

// Atoms (state)
import { progressBarAtom } from '../atoms';
import ProgressBar from '../components/common/ProgressBar';

// Wizard Steps
import EmailCapture from '../components/steps/EmailCapture';
import SocialCapture from '../components/steps/SocialCapture';
import WalletCapture from '../components/steps/WalletCapture';
import CategoryCapture from '../components/steps/CategoryCapture';
import LinksCapture from '../components/steps/LinksCapture';
import AffiliateCodeCapture from '../components/steps/AffiliateCodeCapture';
import Finished from '../components/steps/Finished';
import AuthCallback from '../components/steps/AuthCallback';
import SocialConfirm from '../components/steps/SocialConfirm';
import InterestCapture from '../components/steps/InterestCapture';
import NftCapture from '../components/steps/enthusiast/NftCapture';
import AdditionalWalletCapture from '../components/steps/enthusiast/AdditionalWalletCapture';
import ProjectCapture from '../components/steps/enthusiast/ProjectCapture';
import WhyYouCapture from '../components/steps/enthusiast/WhyYouCapture';
import IndustryCapture from '../components/steps/builder/IndustryCapture';
import WorkingNowCapture from '../components/steps/builder/WorkingNowCapture';
import WorkingFutureCapture from '../components/steps/builder/WorkingFutureCapture';
import WhoCapture from '../components/steps/project/WhoCapture';
import TechCapture from '../components/steps/project/TechCapture';
import WhyCapture from '../components/steps/project/WhyCapture';
import PlatformCapture from '../components/steps/content-creator/PlatformCapture';
import ContentTypeCapture from '../components/steps/content-creator/ContentTypeCapture';
import OftenCapture from '../components/steps/content-creator/OftenCapture';
import FollowingCapture from '../components/steps/content-creator/FollowingCapture';
import OtherWhoCapture from '../components/steps/other/OtherWhoCapture';
import OtherWhatCapture from '../components/steps/other/OtherWhatCapture';
import OtherWhyCapture from '../components/steps/other/OtherWhyCapture';
import Confirm from '../components/steps/Confirm';
import ShortAppFinished from '../components/steps/ShortAppFinished';
import CreativeIndustryCapture from '../components/steps/creative/IndustryCapture';
import CreativeWhatCapture from '../components/steps/creative/WhatCapture';
import Update from '../components/steps/Update';

const Application = () => {
  const location = useLocation();
  const [progressBar] = useRecoilState(progressBarAtom);
  const [prevIndex, setPrevIndex] = useState(-1);
  const currentLocation = location.pathname;

  const pathList = [
    '/',
    '/application/social',
    '/application/callback',
    '/application/social/confirm',
    '/application/email',
    '/application/interests',
    '/application/finished-short-app',
    '/application/wallet',
    '/application/buckets',
    '/application/enthusiast/nft',
    '/application/enthusiast/wallet',
    '/application/enthusiast/project',
    '/application/enthusiast/why-you',
    '/application/builder/industry',
    '/application/builder/now',
    '/application/builder/future',
    '/application/project/who',
    '/application/project/tech',
    '/application/project/why',
    '/application/content-creator/platform',
    '/application/content-creator/content-type',
    '/application/content-creator/often',
    '/application/content-creator/following',
    '/application/creative/industry',
    '/application/creative/what',
    '/application/other/who',
    '/application/other/what',
    '/application/other/why',
    '/application/links',
    '/application/update',
    '/application/affiliate',
    '/application/confirm',
    '/application/finished',
  ];

  useEffect(() => {
    const currentIndex = pathList.findIndex((x) => x === location.pathname);
    setTimeout(() => setPrevIndex(currentIndex), 1000);
  }, [currentLocation]);

  const getLeftRightClass = () => {
    const currentIndex = pathList.findIndex((x) => x === location.pathname);
    const className = currentIndex < prevIndex ? 'slide-right' : 'slide-left';
    return className;
  };

  return (
    <>
      <ProgressBar activeStep={progressBar} />
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-6 offset-sm-3">
            <TransitionGroup component="div" className="App">
              <CSSTransition
                key={currentLocation}
                timeout={{ enter: 800, exit: 400 }}
                classNames="pageSlider"
                mountOnEnter={false}
                unmountOnExit
              >
                <div className={getLeftRightClass()}>
                  <Routes>
                    <Route exact path="/social" element={<SocialCapture />} />
                    <Route exact path="/callback" element={<AuthCallback />} />
                    <Route exact path="/social/confirm" element={<SocialConfirm />} />
                    <Route exact path="/email" element={<EmailCapture />} />
                    <Route exact path="/interests" element={<InterestCapture />} />
                    <Route
                      exact
                      path="/finished-short-app"
                      element={<ShortAppFinished />}
                    />
                    <Route exact path="/wallet" element={<WalletCapture />} />
                    <Route exact path="/buckets" element={<CategoryCapture />} />
                    {/* Enthusiast questions */}
                    <Route exact path="/enthusiast/nft" element={<NftCapture />} />
                    <Route
                      exact
                      path="/enthusiast/wallet"
                      element={<AdditionalWalletCapture />}
                    />
                    <Route
                      exact
                      path="/enthusiast/project"
                      element={<ProjectCapture />}
                    />
                    <Route exact path="/enthusiast/why-you" element={<WhyYouCapture />} />
                    <Route
                      exact
                      path="/enthusiast/"
                      element={<Navigate to="/application/enthusiast/nft" replace />}
                    />
                    {/* Founder/Builder questions */}
                    <Route exact path="/builder/industry" element={<IndustryCapture />} />
                    <Route exact path="/builder/now" element={<WorkingNowCapture />} />
                    <Route
                      exact
                      path="/builder/future"
                      element={<WorkingFutureCapture />}
                    />
                    <Route
                      exact
                      path="/builder/"
                      element={<Navigate to="/application/builder/industry" replace />}
                    />
                    {/* Project member questions */}
                    <Route exact path="/project/who" element={<WhoCapture />} />
                    <Route exact path="/project/tech" element={<TechCapture />} />
                    <Route exact path="/project/why" element={<WhyCapture />} />
                    <Route
                      exact
                      path="/project/"
                      element={<Navigate to="/application/project/who" replace />}
                    />
                    {/* Content creator questions */}
                    <Route
                      exact
                      path="/content-creator/platform"
                      element={<PlatformCapture />}
                    />
                    <Route
                      exact
                      path="/content-creator/content-type"
                      element={<ContentTypeCapture />}
                    />
                    <Route
                      exact
                      path="/content-creator/often"
                      element={<OftenCapture />}
                    />
                    <Route
                      exact
                      path="/content-creator/following"
                      element={<FollowingCapture />}
                    />
                    <Route
                      exact
                      path="/content-creator/"
                      element={
                        <Navigate to="/application/content-creator/platform" replace />
                      }
                    />
                    {/* Project member questions */}
                    <Route
                      exact
                      path="/creative/industry"
                      element={<CreativeIndustryCapture />}
                    />
                    <Route
                      exact
                      path="/creative/what"
                      element={<CreativeWhatCapture />}
                    />
                    <Route
                      exact
                      path="/creative/"
                      element={<Navigate to="/application/creative/industry" replace />}
                    />
                    {/* Other questions */}
                    <Route exact path="/other/who" element={<OtherWhoCapture />} />
                    <Route exact path="/other/what" element={<OtherWhatCapture />} />
                    <Route exact path="/other/why" element={<OtherWhyCapture />} />
                    <Route
                      exact
                      path="/other/"
                      element={<Navigate to="/application/other/who" replace />}
                    />
                    <Route exact path="/links" element={<LinksCapture />} />
                    <Route exact path="/update" element={<Update />} />
                    <Route exact path="/affiliate" element={<AffiliateCodeCapture />} />
                    <Route exact path="/confirm" element={<Confirm />} />
                    <Route exact path="/finished" element={<Finished />} />
                    <Route exact path="/" element={<Navigate to="social" replace />} />
                  </Routes>
                </div>
              </CSSTransition>
            </TransitionGroup>
          </div>
        </div>
      </div>
    </>
  );
};

export default Application;
