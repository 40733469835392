// Libraries
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import authManager from '../components/common/authManager';

const Logout = () => {
  const navigate = useNavigate();

  const doCallback = async () => {
    await authManager.logout();
    navigate('/', { replace: true });
  };

  let isLoaded = false;
  useEffect(() => {
    if (!isLoaded) {
      doCallback();
    }
    isLoaded = true;
  }, []);

  return <></>;
};

export default Logout;
